import request from '@/utils/request'

export function profile() {
    return request({
        url: 'login/profile',
        method: 'get'
    })
}
// 当前用户的个号列表
export function myLoginList(params) {
    return request({
        url: 'thirdoauth/myLoginList',
        method: 'get',
        params
    })
}
// 获取分组的个号列表
export function loginListGroup(params){
    return request({
        url: 'thirdoauth/myThirdLoginLabels',
        method: 'get',
        params
    })
}
// 获取分组的个号列表(新)
export function queryAdAccountLabelListByUserId(params){
    return request({
        url: 'account/queryAdAccountLabelListByUserId',
        method: 'get',
        params
    })
}
// 获取带标签的个号列表
export function myThirdLoginSetHasLabel(params){
    return request({
        url: 'thirdoauth/myThirdLoginSetHasLabel',
        method: 'get',
        params
    })
}
// 设置授权为默认
export function setDefault(data) {
    return request({
        url: 'thirdoauth/setDefault',
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data
    })
}
//  rename
export function rename(data) {
    return request({
        url: `/thirdoauth/rename/${data.id}`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}
// unbind
export function unbind(data) {
    return request({
        url: `/thirdoauth/unbind/${data.id}`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}
// 获取广告账户选项卡信息广告账户
export function adaccouts(params) {
    return request({
        url: `/account/accounts/${params.id}`,
        method: 'get',
        params: {
            ...params
        }
    })
}
// 钉钉免登录
export function dingAuthCode(code,userId) {
    let url = `/login/dingAuthCode?authCode=${code}`
    url = (userId || userId === 0) ? `${url}&userId=${userId}` : url;
    return request({
        url,
        method: "get"
    })
}
// 绑定单点
export function bindGimpId(params) {
    return request({
        url: `/thirdoauth/saveThirdGimpUser`,
        method: 'get',
        params: {
            ...params
        }
    })
}
// 绑定adsPowerId
export function savePowerId(data) {
    return request({
        url: `/thirdoauth/savePowerId`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
}
// 获取列表
export function myThirdLoginSetHasLabelByPage(data) {
    return request({
        url: `/thirdoauth/myThirdLoginSetHasLabelByPage`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        },
        transformRequest: [function (data) {
            return JSON.stringify(data)
        }]
    })
}
