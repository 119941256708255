import request from '@/utils/request';
import { getToken, getUserId } from '@/utils/token';

const gatewayURl = process.env.VUE_APP_GATEWAY;
// 检查广告账号状态
export function checkAccountStatus(params) {
  return request({
    url: `/account/check/${params.id}`,
    method: 'get',
    params,
  });
}

// 账号充值
export function recharge(data) {
  return request({
    url: `/blueMedia/account_recharge`,
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}

// 通过账户查询检验代理合同是否配置vltoken
export function queryAgencyByAdAccountId(params) {
  return request({
    url: `/blueMedia/queryAgencyByAdAccountId`,
    method: 'get',
    params,
    hideErrorMessage: true,
  });
}

// 合同列表
export function queryAgency() {
  return request({
    url: '/blueMedia/queryAgency',
    method: 'get',
  });
}

// 根据账户id获取对应的账单简称
export function findAgencyByAdAccountId(params) {
  return request({
    url: '/blueMedia/findAgencyByAdAccountId',
    params,
  });
}

// 枚举
export function adsConsts() {
  return request({
    baseURL: gatewayURl,
    url: '/fastDelivery/ads/consts',
    params: {
      _token: getToken(),
      _user: getUserId(),
    },
  });
}
