import request from '@/utils/request'
// 获取广告账户
export function getAdAccouts(params) {
  return request({
    url: `/account/accounts/${params.id}`,
    method: 'get',
    params
  })
}
// 获取主页信息
export function getMainPages(params) {
  return request({
    url: '/businessManager/pages',
    method: 'get',
    params
  })
}
// 获取广告像素
export function getPixels(params) {
  return request({
    url: '/businessManager/pixels',
    method: 'get',
    params
  })
}
// 获取像素下的事件信息
export function getPixelEvents(params) {
  return request({
    url: '/businessManager/pixel-event',
    method: 'get',
    params
  })
}
// 广告资产同步
export function synBM(data) {
  return request({
    url: '/businessManager/syn-bm',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    timeout: 0,
    data
  })
}
// 根据广告账号获取偏好设置数据
export function getSetsByAccounts(data) {
  return request({
    url: '/account/getSetsByAccounts',
    method: 'post',
    data,
    transformRequest: [function (data) {
      return JSON.stringify(data)
    }]
  })
}
// 获取命名规则下拉数据字典项
export function getDicts() {
  return request({
    url: '/account/getDicts',
    method: 'get'
  })
}
// 根据广告账户获取像素
export function getPixelsByAccounts(data) {
  return request({
    url: '/account/getPixelsByAccounts',
    method: 'post',
    data,
    transformRequest: [function (data) {
      return JSON.stringify(data)
    }]
  })
}
// 广告账户偏好设置
export function setContent(data) {
  return request({
    url: '/account/set-content',
    method: 'post',
    data,
    transformRequest: [function (data) {
      return JSON.stringify(data)
    }]
  })
}
//获取主页帖子
export function apPosts(params) {
  return request({
    url: '/fbConnection/ap-posts',
    method: 'get',
    params
  })
}
// /account/clearCache清理主页和像素的缓存
export function clearCache(data) {
  return request({
    url: '/account/clearCache',
    method: 'post',
    data,
    transformRequest: [function (data) {
      return JSON.stringify(data)
    }]
  })
}
// 获取单点账号偏好设置
export function getSetsByUser(data) {
  return request({
    url: '/account/getSetsByUser',
    method: 'post',
    data,
    transformRequest: [function (data) {
      return JSON.stringify(data)
    }]
  })
}
// 设置单点账号偏好设置
export function updateUserNamingRule(data) {
  return request({
    url: '/account/updateUserNamingRule',
    method: 'post',
    data,
    transformRequest: [function (data) {
      return JSON.stringify(data)
    }]
  })
}