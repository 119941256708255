import request from '@/utils/request';
import axios from 'axios';
// 广告列表
export function report(data, that, thirdNum) {
  return request({
    url: '/ads/report',
    method: 'post',
    data: data,
    timeout: 0,
    headers: {
      thirdLoginUserId: thirdNum,
      adAccountId: JSON.parse(data).account_id,
    },
    cancelToken: new axios.CancelToken(function executor(c) {
      // 设置 cancel token
      // console.log(c);
      if (that) {
        that.source = c;
      }
    }),
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}
// 自定义列，所有列
export function allColumns(params) {
  console.log('allColumns', params);
  return request({
    url: '/ads/allColumns',
    method: 'get',
    params,
    headers: {
      adAccountId: params.accountId,
    },
  });
}

// 我的自定义列
export function myColumns(params) {
  return request({
    url: '/ads/myColumns',
    method: 'get',
    params: {
      ...params,
    },
    headers: {
      adAccountId: params.accountId,
    },
  });
}

// 保存自定义列
export function saveColumn(data, adAccountId) {
  return request({
    url: '/ads/saveColumn',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
      adAccountId,
    },
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}
// 保存自定义列
export function deleteColumn(data, adAccountId) {
  return request({
    url: '/ads/deleteColumn',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
      adAccountId,
    },
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}

// 广告数量合计
export function adsCount(params) {
  return request({
    url: '/ads/adsCount',
    method: 'get',
    params: {
      ...params,
    },
    headers: {
      adAccountId: params.accountId,
    },
  });
}

// 细分规则
export function breakdownRules(params, adAccountId) {
  return request({
    url: '/ads/breakdownRules',
    method: 'get',
    params,
    headers: {
      adAccountId,
    },
  });
}

// 细分
export function breakdown(data) {
  return request({
    url: '/ads/breakdown',
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return data;
      },
    ],
    headers: {
      adAccountId: JSON.parse(data).account_id,
    },
  });
}

// 查询条件->我的查询条件
export function myFilters(data) {
  return request({
    url: '/ads/myFilters',
    method: 'get',
  });
}

// 查询条件->保存我的查询条件
export function saveFilter(data) {
  return request({
    url: '/ads/saveFilter',
    method: 'post',
    data: data,
    timeout: 0,
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}
// 删除我的筛选条件
export function deleteFilter(data) {
  return request({
    url: '/ads/deleteFilter',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}
// 广告账户
export function adaccouts(params) {
  return request({
    url: `/account/accounts/${params.id}`,
    method: 'get',
    params: {
      ...params,
    },
  });
}

// 广告账户-配置独立的请求头-thirdLoginUserId
export function adaccoutsHeader(params) {
  return request({
    url: `/account/accounts/${params.id}`,
    method: 'get',
    headers: { thirdLoginUserId: params.id },
    params: {
      ...params,
    },
  });
}

// 获取广告账户下的主页-配置独立的请求头-thirdLoginUserId
export function accountPagesHeader(params, id) {
  return request({
    url: '/ads/accountPages',
    method: 'get',
    headers: { thirdLoginUserId: id },
    timeout: 0,
    params: {
      ...params,
    },
  });
}

// 获取广告账户下的像素-配置独立的请求头-thirdLoginUserId
export function accountPixelHeader(params, that, id) {
  return request({
    // baseURL:'http://192.168.1.18:8088/api',
    url: '/ads/accountPixel',
    cancelToken: new axios.CancelToken(function executor(c) {
      // 设置 cancel token
      // console.log(c);
      if (that) {
        that.source = c;
      }
    }),
    method: 'get',
    headers: { thirdLoginUserId: id },
    params: {
      ...params,
    },
  });
}

// 修改名称
export function mutateName(data, id) {
  return request({
    url: '/ads/mutateName',
    method: 'post',
    data: data,
    timeout: 0,
    headers: { thirdLoginUserId: id },
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}

// 改变状态(删除广告也用这个接口，status传delete即可)
export function mutateStatus(data, id) {
  return request({
    url: '/ads/mutateStatus',
    method: 'post',
    data: data,
    timeout: 0,
    headers: { thirdLoginUserId: id },
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}
export function mutatePricing(data, headers) {
  return request({
    url: '/ads/mutatePricing',
    method: 'post',
    data: data,
    timeout: 0,
    headers: { ...headers },
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}
// 获取竞价信息
export function pricingInfo(data, id) {
  return request({
    url: '/ads/pricingInfo',
    method: 'post',
    data: data,
    timeout: 0,
    headers: { thirdLoginUserId: id },
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}

// 复制广告系列-检测当前账户
export function copyCampaignsCheck(data, id) {
  return request({
    url: '/ads/copyCampaignsCheck',
    method: 'post',
    data: data,
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded'
    // },
    timeout: 0,
    headers: { thirdLoginUserId: id },
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}

// 复制广告系列
export function copyCampaigns(data) {
  return request({
    url: '/ads/copyCampaigns',
    method: 'post',
    data: data,
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded'
    // },
    timeout: 0,
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}
// ly-start-图表抽屉
// 广告列表常量
export function reportConsts(params) {
  return request({
    url: '/ads/reportConsts',
    method: 'get',
    params,
  });
}
// 图表坐标轴
export function getAxisData(data) {
  return request({
    url: '/ads/coordinates',
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 时间对比时得到chart和table数据
export function getCompareDateDatas(data) {
  return request({
    url: '/ads/breakdowns',
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 复制广告系列
export function copyAd(data) {
  return request({
    url: '/ads/copyAd',
    method: 'post',
    data: data,
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded'
    // },
    timeout: 0,
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}
// 创建异步报告
export function asyncReportGen(data) {
  return request({
    url: '/ads/asyncReportGen',
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 查询异步报告进度
export function asyncReportQuery(params) {
  return request({
    url: '/ads/asyncReportQuery',
    method: 'get',
    params,
  });
}
// 下载报告
export function asyncReportDownload(params) {
  return request({
    url: '/ads/asyncReportDownload',
    method: 'get',
    params,
  });
}
// 复制广告进度查询
export function queryAsyncAdCopyResult(data) {
  return request({
    url: '/ads/queryAsyncAdCopyResult',
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 广告列表广告预览
export function adPreview(params) {
  return request({
    url: 'ads/adPreview',
    method: 'get',
    params,
  });
}
// 批量修改广告创意
export function mutateAds(data) {
  return request({
    url: '/ads/mutateAds',
    method: 'post',
    data,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 从广告列表创建广告组
export function adsetForCreate(params, id) {
  return request({
    url: '/ads/adsetForCreate',
    method: 'get',
    headers: { thirdLoginUserId: id },
    params,
  });
}
// 从广告列表创建广告
export function adForCreate(params, id) {
  return request({
    url: '/ads/adForCreate',
    method: 'get',
    headers: { thirdLoginUserId: id },
    params,
  });
}
// 创建广告
export function createAd(data, id) {
  return request({
    url: '/ads/createAd',
    method: 'post',
    data,
    headers: { thirdLoginUserId: id },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 预算类型转换
export function budgetOptimization(data, id) {
  return request({
    url: '/ads/budgetOptimization',
    method: 'post',
    data,
    headers: { adAccountId: id },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 批量修改广告组像素
export function mutateAdsetPixel(data, id) {
  return request({
    url: '/ads/mutateAdsetPixel',
    method: 'post',
    timeout: 0,
    data,
    headers: { thirdLoginUserId: id },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 获取广告所在的广告组
export function sameCampaignAdsets(data, id) {
  return request({
    url: '/ads/sameCampaignAdsets',
    method: 'post',
    data,
    headers: { thirdLoginUserId: id },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 批量获取广告组信息
export function batchFetchAdSet(data, id) {
  return request({
    url: '/ads/batchFetchAdSet',
    method: 'post',
    data,
    headers: { thirdLoginUserId: id },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 批量更新广告组
export function batchUpdateAdSet(data, id) {
  return request({
    url: '/ads/batchUpdateAdSet ',
    method: 'post',
    data,
    headers: { thirdLoginUserId: id },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 批量获取广告信息
export function batchFetchAd(data, id) {
  return request({
    url: '/ads/batchFetchAd',
    method: 'post',
    data,
    headers: { thirdLoginUserId: id },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 批量更新广告
export function batchUpdateAd(data, id) {
  return request({
    url: '/ads/batchUpdateAd ',
    method: 'post',
    timeout: 60000,
    data,
    headers: { thirdLoginUserId: id },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 获取广告广告组信息
export function batchEditFetch(data, id) {
  return request({
    url: '/ads/batchEditFetch',
    method: 'post',
    data,
    headers: { thirdLoginUserId: id },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 跨帐户-广告列表
export function batchReport(data, that) {
  return request({
    url: '/batchads/report',
    method: 'post',
    data: data,
    timeout: 0,
    cancelToken: new axios.CancelToken(function executor(c) {
      // 设置 cancel token
      // console.log(c);
      if (that) {
        that.source = c;
      }
    }),
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}
// 账户列表
export function switchActByKeyword(data, that) {
  console.log('==1', that, that.source);
  if (that && that.source) {
    that.source();
  }
  return request({
    url: '/ads/switchActByKeyword',
    method: 'post',
    data,
    cancelToken: new axios.CancelToken(function executor(c) {
      if (that) {
        that.source = c;
      }
    }),
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}

// 单点用户下所有的广告账户
export function allAdAccounts() {
  return request({
    url: '/gimpUser/allAdAccounts',
    method: 'GET',
  });
}
// 批量修改广告组广告
export function batchEdit(data, id) {
  return request({
    url: '/ads/batchEdit',
    method: 'post',
    data,
    timeout: 0,
    headers: { thirdLoginUserId: id },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 获取草稿箱数据
export function draftForUpdate(params) {
  return request({
    url: '/ads/draftForUpdate',
    method: 'get',
    params,
  });
}
// 获取复制的参数
export function ecFetch(data, id) {
  return request({
    url: '/ads/ecFetch',
    method: 'post',
    data,
    timeout: 0,
    headers: { thirdLoginUserId: id },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 创建草稿
export function createDraft(data, id) {
  return request({
    url: '/ads/createDraft',
    method: 'post',
    data,
    timeout: 0,
    headers: { thirdLoginUserId: id },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 修改草稿
export function updateDraft(data) {
  return request({
    url: '/ads/updateDraft',
    method: 'post',
    data,
    timeout: 0,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 批量修改网域
export function addConversionDomain(data) {
  return request({
    url: '/ads/addConversionDomain',
    method: 'post',
    data,
    timeout: 0,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 根据受众获取广告组名称
export function namingByTargeting(data) {
  return request({
    url: '/ads/namingByTargeting',
    method: 'post',
    data,
    timeout: 0,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
    transformResponse: [
      function (data) {
        // 对 data 进行任意转换处理
        // console.log(data);
        data = JSON.parse(data);
        if (data.code == 1) {
          data.code = 0;
          data.data = {};
        }
        return data;
      },
    ],
  });
}
// 提醒打分，收集 /alert/alertScore
export function alertScore(params) {
  return request({
    url: '/alert/alertScore',
    method: 'get',
    params,
  });
}
// 转移草稿到其他账户 /ads/draftsTransfer
export function draftsTransfer(params) {
  return request({
    url: '/ads/draftsTransfer',
    method: 'get',
    params,
  });
}
// 获取日志
export function queryBudgetList(params, id) {
  return request({
    url: '/ads/queryBudgetList',
    method: 'get',
    params,
    headers: { thirdLoginUserId: id },
  });
}
// 批量修改主页
export function updateAdPage(data, id) {
  return request({
    url: '/ads/updateAdPage',
    method: 'post',
    timeout: 0,
    data,
    headers: { thirdLoginUserId: id },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 获取操作日志
export function queryAdEventList(params) {
  return request({
    url: 'ads/queryAdEventList',
    method: 'get',
    params,
  });
}
// 根据广告id 获取广告系列列表
export function otherCampaigns(data, id) {
  return request({
    url: '/ads/otherCampaigns',
    method: 'post',
    timeout: 0,
    data,
    headers: { thirdLoginUserId: id },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 设置定时开启
export function settingAdScheduleOpenTask(data) {
  return request({
    baseURL: process.env.VUE_APP_LABEL,
    url: '/ads/settingAdScheduleOpenTask',
    method: 'post',
    data: data,
    timeout: 0,
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}
// 获取定时 列表
export function queryScheduleOpenTask(data) {
  return request({
    url: '/ads/queryScheduleOpenTask',
    method: 'post',
    data: data,
    timeout: 0,
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}
// 设置定时修改预算
export function settingAdScheduleUpdateBudgetTask(data) {
  return request({
    baseURL: process.env.VUE_APP_LABEL,
    url: `/ads/settingAdScheduleUpdateBudgetTask`,
    method: 'post',
    data: data,
    timeout: 0,
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}
// 取消定时修改预算
export function delAdScheduleUpdateBudgetTask(data) {
  return request({
    baseURL: process.env.VUE_APP_LABEL,
    url: `/ads/delAdScheduleUpdateBudgetTask`,
    method: 'post',
    data: data,
    timeout: 0,
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}
// 取消定时开启任务
export function delAdScheduleOpenTask(data) {
  return request({
    baseURL: process.env.VUE_APP_LABEL,
    url: `/ads/delAdScheduleOpenTask`,
    method: 'post',
    data: data,
    timeout: 0,
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}

// 查询主页广告容量
export function pageVolumes(params, id) {
  return request({
    url: '/ads/pageVolumes',
    method: 'get',
    headers: { thirdLoginUserId: id },
    params,
  });
}

// AI助手更新
export function AIUpload(data) {
  return request({
    url: '/alert/ai-suggestion',
    method: 'post',
    data: JSON.stringify(data),
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}

// 查询用户的广告标签列表
export function queryConfigLabel(params, id) {
  return request({
    baseURL: process.env.VUE_APP_LABEL,
    url: '/ads/queryConfigLabel',
    method: 'get',
    params,
  });
}
// 添加标签
export function saveConfigLabel(data) {
  return request({
    baseURL: process.env.VUE_APP_LABEL,
    url: '/ads/saveConfigLabel',
    method: 'post',
    data: JSON.stringify(data),
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}
// 获取经营分析数据看板
export function multipleAnalysis(data) {
  return request({
    baseURL: process.env.VUE_APP_ADREPORT_GETWARY,
    url: `/dataware/multipleAnalysis`,
    method: 'post',
    data: data,
    timeout: 0,
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}
// 删除标签
export function delLabel(data) {
  return request({
    baseURL: process.env.VUE_APP_LABEL,
    url: `/ads/delLabel?labelId=${data.labelId}`,
    method: 'post',
    data: JSON.stringify(data),
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}
// 添加标签广告关联关系
export function saveAdLabel(data) {
  return request({
    baseURL: process.env.VUE_APP_LABEL,
    url: `/ads/saveAdLabel`,
    method: 'post',
    data: JSON.stringify(data),
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}
// 删除标签广告的关联关系
export function delAdLabel(data) {
  return request({
    baseURL: process.env.VUE_APP_LABEL,
    url: `/ads/delAdLabel`,
    method: 'post',
    data: JSON.stringify(data),
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}
// 直接复制广告
export function directCopy(data) {
  return request({
    url: `/ads/directCopy`,
    method: 'post',
    data: JSON.stringify(data),
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}

// 自动绑定关联关系
export function autoBind(data) {
  return request({
    baseURL: process.env.VUE_APP_NEWTEST,
    url: `/giam/saleCampaign/autoBind`,
    method: 'post',
    data: JSON.stringify(data),
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}
// 自动关联商品
export function bindAuto(data) {
  return request({
    url: `/saleBind/bindAuto`,
    method: 'post',
    data: JSON.stringify(data),
    hideErrorMessage: true,
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}

// 取消定时改出价
export function delUpdateBidTask(data) {
  return request({
    baseURL: process.env.VUE_APP_LABEL,
    url: `/ads/delUpdateBidTask`,
    method: 'post',
    data: data,
    timeout: 0,
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}
// 定时改出价
export function settingAdScheduleUpdateBidTask(data) {
  return request({
    baseURL: process.env.VUE_APP_LABEL,
    url: `/ads/settingAdScheduleUpdateBidTask`,
    method: 'post',
    data: data,
    timeout: 0,
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}

// 账户设置别名
export function actNameSet(data) {
  return request({
    url: '/account/actNameSet',
    method: 'post',
    data: data,
    timeout: 0,
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}
// 清空账户缓存
export function unsetContent(params) {
  return request({
    url: '/account/unset-content',
    method: 'get',
    params,
  });
}
